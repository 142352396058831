<template>
  <UserProfileCardList category="screener" />
</template>

<script>
import UserProfileCardList from "./../../components/UserProfileCardList.vue"

export default {
  components: {
    UserProfileCardList
  }
}
</script>